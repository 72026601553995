.Wrapper {
  height: 74px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    90deg,
    #ffcc00,
    #ffcc00 48%,
    #ffe57f 70%,
    #fff0b2
  );
}

.DividerHorizontal {
  width: 80%;
  background-color: #ebebeb;
  height: 1px;
}

.ColorWrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.colorsLabel {
  color: #eb131e;
}

.DividerVertical {
  width: 4px;
  height: 60%;
  margin-left: 20px;
  background-color: #ababab;
}

.popOver {
  z-index: 2;
  margin-right: 10px;
}
